$(function() {
  var max_height = {};
  var elements = {};

  $('.js-uniform-height').each(function() {
    var $this = $(this);
    var key = $this.data('target');
    if (!key) {
      key = parseInt($this.offset().top);
    }

    if (!elements[key]) {
      elements[key] = [];
    }
    elements[key].push($this);

    var height = $this.height();
    if (!max_height[key] || height > max_height[key]) {
      max_height[key] = height;
    }
  });

  for (var key in elements) {
    for (var i=0; i<elements[key].length; i++) {
      elements[key][i].height(max_height[key]);
    }
  }
})
